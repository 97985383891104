@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./fonts/Font.css";

* {
  font-family: "SCDream", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./assets/images/backGroundImg2.png");
  background-position: 1%;
  background-color: black;
  background-size: cover;
  background-repeat: no-repeat;

  /* background-color: rgb(30 41 59 / 0.8); */

  /* background-color: rgba(30, 41, 59, .8); */
}
@media only screen and (max-width: 767px) {
  /* 모바일 화면에서 적용될 스타일 코드 */
  body {
    width: auto;
    margin: 0 auto;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
