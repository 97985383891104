@font-face {
  font-family: "SCDream";
  font-style: normal;
  font-weight: 100;
  src: url("./SCDream1.otf") format("opentype");
}
@font-face {
  font-family: "SCDream";
  font-style: normal;
  font-weight: 200;
  src: url("./SCDream2.otf") format("opentype");
}
@font-face {
  font-family: "SCDream";
  font-style: normal;
  font-weight: 300;
  src: url("./SCDream3.otf") format("opentype");
}
@font-face {
  font-family: "SCDream";
  font-style: normal;
  font-weight: 400;
  src: url("./SCDream4.otf") format("opentype");
}
@font-face {
  font-family: "SCDream";
  font-style: normal;
  font-weight: 500;
  src: url("./SCDream5.otf") format("opentype");
}
@font-face {
  font-family: "SCDream";
  font-style: normal;
  font-weight: 600;
  src: url("./SCDream6.otf") format("opentype");
}
@font-face {
  font-family: "SCDream";
  font-style: normal;
  font-weight: 700;
  src: url("./SCDream7.otf") format("opentype");
}
@font-face {
  font-family: "SCDream";
  font-style: normal;
  font-weight: 800;
  src: url("./SCDream8.otf") format("opentype");
}
@font-face {
  font-family: "SCDream";
  font-style: normal;
  font-weight: 900;
  src: url("./SCDream9.otf") format("opentype");
}
